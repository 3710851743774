<template>
  <div class="edit">
    <!--顶部-->
    <header-nav :current="current"></header-nav>
    <div class="content">
      <div class="top">
        <div class="test-title">
          <strong>资质申请</strong>
        </div>
        <div class="apply-tips">
          <p v-if="$route.query.id == 1 || $route.query.id == 2">
            <!--本量表管制等级：C-->
            <!--<br>可在具有特教或测验专业人士督导下使用的教师。-->
            <br>请填写下方资质申请所需资料，如属于团体申请，申请账号数量超过一组的情况，请上传多人资格证件压缩文档。资料递交成功后，请留意后台申请列表栏目的申请状态。申请通过后，会由我们专人与您联络参与本量表之测验研习，通过考核者方可获得使用资格。未通过者，我们亦会以邮件方式予以通知。

          </p>
          <p v-if="$route.query.id == 3 || $route.query.id == 4">
            <!--本量表管制等级：C -->
            <!--<br>可在具有特教或测验专业人士督导下使用的教师。-->
            <br>请填写下方资质申请所需资料，如属于团体申请，申请账号数量超过一组的情况，请上传多人资格证件压缩文档。资料递交成功后，请留意后台申请列表栏目的申请状态。申请通过后，会由我们专人与您联络参与本量表之测验研习，通过考核者方可获得使用资格。未通过者，我们亦会以邮件方式予以通知。
          </p>
          <p v-if="$route.query.id == 5">
            <!--本量表管制等级：A-->
            <!--<br>具有心理治疗师、职能治疗师、物理治疗师、语言治疗师、公民办特教老师专业资格。-->
            <br>请填写下方资质申请所需资料，如属于团体申请，申请账号数量超过一组的情况，请上传多人资格证件压缩文档。资料递交成功后，请留意后台申请列表栏目的申请状态。申请通过后，可参与本量表之在线测验研习，学满时效可获得使用资格。未通过者，我们亦会以邮件方式予以通知。
          </p>
          <p v-if="$route.query.id == 6">
            <!--本量表管制等级：A-->
            <!--<br>具有心理治疗师、职能治疗师、物理治疗师、语言治疗师、公民办特教老师专业资格。-->
            <br>请填写下方资质申请所需资料，如属于团体申请，申请账号数量超过一组的情况，请上传多人资格证件压缩文档。资料递交成功后，请留意后台申请列表栏目的申请状态。申请通过后，会由我们专人与您联络参与本量表之测验研习，通过考核者方可获得使用资格。未通过者，我们亦会以邮件方式予以通知。
          </p>
        </div>
      </div>
      <div class="form">
        <div class="inputList" v-for="(list, index) in userInforList" :key="index" :class="{on: list.key == 'remark'}">
            <div class="title">{{list.name}}<span class="spl">*</span></div>
            <div class="input" v-if="list.key == 'remark'">
              <!-- <edit-div class="textarea" v-model="form[list.key]"></edit-div> -->
              <textarea class="textarea" v-model="form[list.key]" rows="4"></textarea>
            </div>
            <div v-else-if="list.key =='licence'" class="upImg">
                <div class="btndiv">
                   <button class="btns">
                      <span>上传图片</span>
                      <input class="upload-input" type="file"
                        @change="getPickerImg($event,'licence')" />
                    </button>
                </div>
                <div class="show-img" v-if="licence">
                  <img :src="licence">
                </div>
            </div>
            <div v-else-if="list.key =='certificate'" class="upImg">
                <div class="btndiv">
                  <button class="btns">
                    <span>上传文件</span>
                    <input class="upload-input" type="file"
                      @change="getPickerImg($event,'certificate')" />
                  </button>
                </div>
                <div class="name">{{certificateName}}</div>
                <div class="tips">（如申请账号数量超过一组，请上传多人资格证件压缩文档）</div>
            </div>
            <div class="input" v-else>
              <input type="text"  v-model="form[list.key]">
            </div>
        </div>
        <div class="form-item w-g" style="padding-right: 18%;">
          <div class="input">
            <button class="btn" @click="submitForm">提交</button>
          </div>
        </div>
        <qi-upload/>
      </div>
    </div>
    <!--底部-->
    <footer-nav></footer-nav>
    <qiniu-upload ref="qiniuUpload"></qiniu-upload>

  </div>
</template>

<script>
import HeaderNav from '@/components/HeaderNav'
import FooterNav from '@/components/FooterNav'
import EditDiv from '@/components/EditDiv'
import myDatepicker from 'vue-datepicker-simple/datepicker-2.vue'
import QiniuUpload from '@/components/QiniuUpload'

export default {
  name: 'user',
  data () {
    return {
      current: 'evaluation',
      form: {
          company:'',
          union_code:'',
          department:'',
          nickname:'',
          position:'',
          mobile:'',
          email:'',
          needs_num:'',
          licence:'',
          certificate:'',
          remark:''
      },
      licence:'',
      licenceName:'',
      certificate:'',
      certificateName:'',
      //userInforList:["title_id","company","union_code","department","nickname","position","mobile","email","needs_num","licence","certificate","remark"],
      userInforList:[
          {name:'单位全称',key:"company"},
          {name:'统一社会信用代码',key:"union_code"},
          {name:'所在部门',key:"department"},
          {name:'姓名',key:"nickname"},
          {name:'职位',key:"position"},
          {name:'手机号',key:"mobile"},
          {name:'Email',key:"email"},
          {name:'申请账号数量',key:"needs_num"},
          {name:'单位营业执照',key:"licence"},
          {name:'资格证件',key:"certificate"},
          {name:'其他说明',key:"remark"}
      ],
      option:{
        inputStyle:{
          'width':'98%',
          'height':'38px'
        }

      },
      headimg:{
        licence:'',
        certificate:''
      }
    }
  },
  methods: {
    getbaseInfo: function () {
      const token = localStorage.getItem('token')
      const id    =  this.$route.query.id;
      let params = this.form;
      if(!token && !id){
          this.$router.push({path:'/login'})
      }
    },
    getPickerImg(e,key){
        let files = e.target.files
        let Reg = /.*?\.(jpg|jpeg|png|zip|rar)$/;
        let accept = Reg.test(files[0].name)
        if(accept){
            let qiniu = this.$refs.qiniuUpload
            qiniu.qiniuUpload(files, (res) => {
            this[key + 'Name'] = res[0].key;
            this[key] =  this.GLOBAL.qnDomail + res[0].key;
          })
        }else{
          this.$layer.msg('请上传正确格式的文件')
        }


    },
    submitForm: function () {
      console.log(this.form)
      let { company,
          union_code,
          department,
          nickname,
          position,
          mobile,
          email,
          needs_num,
          licence,
          certificate,
          remark} = this.form;
      let _self = this;
      let pramas = Object.assign(this.form,{
         title_id:_self.$route.query.id
      })
      if(!company){
        this.$layer.msg('请输入单位全称！')
        return true
      }
       if(!union_code){
        this.$layer.msg('请输入统一社会信用代码！')
        return true
      }
       if(!department){
        this.$layer.msg('请输入所在部门！')
        return true
      }
      if(!nickname){
        this.$layer.msg('请输入姓名！')
        return true
      }
      if(!position){
        this.$layer.msg('请输入职位！')
        return true
      }
      if(!mobile){
        this.$layer.msg('请输入手机号！')
        return true
      }
      if(!email){
        this.$layer.msg('请输入Email！')
        return true
      }
       if(!needs_num){
        this.$layer.msg('请输入申请账号数量！')
        return true
      }
       if(!this.licence){
        this.$layer.msg('请上传单位营业执照！')
        return true
      }
      if(!this.certificate){
        this.$layer.msg('请上传资格证件！')
        return true
      }
      if(!remark){
        this.$layer.msg('请输入备注！')
        return true
      }
      this.form.licence = this.licence;
      this.form.certificate = this.certificate;
       this.$post('question/qualification', this.form).then(res => {
         this.$layer.msg(res.msg)
         if (res.code == 1) {
           setTimeout(() => {
             this.$router.push('/applyList')
           },1000)
         }
      }).catch(response => {
        this.$layer.msg('网络异常，请稍后重试！')
      })
    }
  },
  components: {
    HeaderNav,
    FooterNav,
    EditDiv,
    'date-picker': myDatepicker,
    QiniuUpload
  },
  mounted: function () {
    const token = localStorage.getItem('token')
    const id    =  this.$route.query.id;
    if(!token && !id){
        this.$router.push({path:'/login'})
    }
  },
  created () {
    if (this.$route.query.id) {
      this.form.title_id = this.$route.query.id
    }
  }
}
</script>

<style scoped lang="less">
@import url('../assets/css/page/test');
.form{
  width: 800px;
  margin: 40px auto;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;
  .inputList{
    width: 50%;
    .title{
        line-height: 42px;
        position: relative;
        display: inline-block;
        .spl{
          font-size: 16px;
          color: red;
          position: absolute;
          right: -12px;
          top: 3px;
        }
      }
     .input{
        width: 100%;
        display: flex;
        flex-direction: row;
        position: relative;
        input{
          width: 65%;
          height: 26px;
        }
        .textarea{
          border: solid #333 1px;
          line-height: 1.6;
          padding: 10px;
          width: 80%;
        }
        .mark{
          width: 35%;
          display: inline-block;
          color: #c1b8b8;
          padding: 0 10px;
          font-size: 12px;
        }
     }
     .upImg{
       .show-img{
         margin-top: 10px;
         height: 120px;
         img{
           display: inline;
           height: 100%;
         }
       }
       .name{
         font-size: 16px;
         padding-top: 10px;
       }
       .tips{
         font-size: 12px;
         color: #f39902;
         padding-top: 10px;
       }
        .btndiv{
          .btns{
                display: inline-block;
                color: #fff;
                font-size: 16px;
                line-height: 20px;
                padding: 10px;
                border-radius: 4px;
                background-color: #f39902;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                width: 100px;
                margin-left: 10px;
                // margin: 40px auto;
                border: none;
                outline: medium;
                cursor: pointer;
                position: relative;
            input{
                  width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0;
                  position: absolute;
                  opacity: 0;
            }
          }
        }
      }
  }
  .on {
    width: 100%;
  }
}
.apply-tips{
  padding: 20px 0;
  font-size: 14px;
  color: #666;
  line-height: 1.6;
}

.form-item{
  margin-bottom: 10px;
  width: 48%;
  margin: 0 1%;
  float: left;
}
.w-g{
  width: 100%
}
.form-item .title{
  line-height: 42px;
  padding-left: 10px;
}
.form-item .input .vue-datepicker input{
  width: 98%!important;
  height: 39px!important;
}
.form-item .birth{
 display: block;
  width: 100%;
  height: 39px;
}
.form-item .input input{
  display: block;
  width: 70%;
  color: #333;
  font-size: 14px;
  line-height: 20px;
  border: solid #dbdbdb 1px;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
}
.form-item .input .vue-datepicker{
  width: 100%;
  border: solid #dbdbdb 1px;
}
.form-item .input .vue-datepicker input{
  width: 100%;
}
.form-item .input button{
    display: block;
    color: #fff;
    font-size: 16px;
    line-height: 20px;
    padding: 10px;
    border-radius: 4px;
    background-color: #f39902;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 120px;
    margin: 71px auto;
    border: none;
    outline: medium;
    cursor: pointer;
    box-shadow: 0 5px 20px rgba(218, 149, 46, 0.41);
}
.db-bottom #birthday{
  width: 96% !important;
  height: 100% !important;
}
</style>
